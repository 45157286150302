/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createReservation = /* GraphQL */ `mutation CreateReservation($appointmentData: String!) {
  createReservation(appointmentData: $appointmentData) {
    success
    paypayUrl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationMutationVariables,
  APITypes.CreateReservationMutation
>;
export const deleteReservation = /* GraphQL */ `mutation DeleteReservation($ReservationHash: String!) {
  deleteReservation(ReservationHash: $ReservationHash) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationMutationVariables,
  APITypes.DeleteReservationMutation
>;
