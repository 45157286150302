import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography, Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const NewReservation: React.FC = () => {
  const [agreeToPolicy, setAgreeToPolicy] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    if (agreeToPolicy) {
      navigate(path);
    } else {
      alert('予約を進めるには、プライバシーポリシーに同意する必要があります。');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center"
        textAlign="center"
        mt={4}  // 上部にマージンを追加
      >
        <Button 
          onClick={() => handleNavigation('/reservation/sekine-jibika')} 
          variant="outlined" 
          color="primary" 
          fullWidth
          style={{ fontSize: '1.2rem', marginBottom: '2rem'}} 
          sx={{ margin: 1 }}
        >
          せきね耳鼻咽喉科
        </Button>
        <Button 
          onClick={() => handleNavigation('/reservation/wako-jibika')} 
          variant="outlined" 
          color="primary" 
          fullWidth
          style={{ fontSize: '1.2rem' }} 
          sx={{ margin: 1 }}
        >
          和光耳鼻咽喉科
        </Button>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToPolicy}
                onChange={(e) => setAgreeToPolicy(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                  プライバシーポリシー
                </Link>
                に同意して予約に進みます。
              </Typography>
            }
          />
        </Box>
      </Box>
    </Container>
  );
};

export default NewReservation;
