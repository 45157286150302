import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';

const ServiceDescription: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          WakoNavi予約代行システムについて
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          サービス概要
        </Typography>
        <Typography paragraph>
          WakoNavi予約代行システムは、医療機関の予約をスムーズに行うためのオンラインサービスです。患者様が事前に日程を選択し、指定された医療機関の予約を自動的に取ることができます。
          <br />
          基本的に1番目の番号を取得するように努力しますが、利用者が多い場合は2番目以降になる可能性があります。本システムの不具合によって予約が失敗した場合は返金対応いたします。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          ご利用方法
        </Typography>
        <Typography paragraph>
          1. アカウント作成<br />
          2. 希望の医療機関を選択<br />
          3. 日時を選択<br />
          4. 予約内容を確認<br />
          5. PayPayで決済<br />
          6. 予約完了
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          料金
        </Typography>
        <Typography paragraph>
          予約代行手数料：500円（税込）/ 1予約 <br />
          ※料金は利用状況に応じて変更される可能性があります。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          決済方法
        </Typography>
        <Typography paragraph>
          PayPayによるオンライン決済
        </Typography>

        <Typography variant="h6" gutterBottom>
          連絡先
        </Typography>
        <Typography paragraph>
          メールアドレス：tomitty3@gmail.com
        </Typography>
        
        <Box mt={4}>
          <Button variant="contained" color="primary" href="/reservation/menu">
            予約を開始する
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ServiceDescription;