import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const LegalInformation: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          特定商取引法に基づく表記
        </Typography>

        <Typography variant="h6" gutterBottom>
          事業者名
        </Typography>
        <Typography paragraph>
          WakoNavi
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          運営責任者
        </Typography>
        <Typography paragraph>
          富田 京志
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          連絡先
        </Typography>
        <Typography paragraph>
          メールアドレス：tomitty3@gmail.com
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          販売価格
        </Typography>
        <Typography paragraph>
          各サービスごとに表示された価格（税込）
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          商品代金以外の必要料金
        </Typography>
        <Typography paragraph>
          インターネット接続料金、通信料金はお客様のご負担となります。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          支払方法
        </Typography>
        <Typography paragraph>
          PayPay
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          支払時期
        </Typography>
        <Typography paragraph>
          ご注文時にお支払いが確定します。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          サービスの提供時期
        </Typography>
        <Typography paragraph>
          ご注文後、速やかにサービスをご提供いたします。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          キャンセル・返品について
        </Typography>
        <Typography paragraph>
          商品の性質上、サービス提供後のキャンセル・返品はお受けできません。
        </Typography>

        <Typography variant="h6" gutterBottom>
          事業者の住所
        </Typography>
        <Typography paragraph>
          事業者の住所については、個人事業主のため、非公開にしています。メールでお問い合わせいただければ、正当な理由がある場合に限り、速やかに個別開示させていただきます。
        </Typography>
      </Box>
    </Container>
  );
};

export default LegalInformation;