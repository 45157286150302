import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import theme from './theme';

const Menu: React.FC = () => (
  <Box 
    display="flex" 
    justifyContent="space-around" // アイコンを等間隔に配置
    alignItems="center" 
    flexDirection="row" // アイコンを横並びにする
    flexWrap="wrap" // コンテンツがオーバーフローする場合は折り返し
    m={6}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button 
        component={Link} 
        to="/reservation/new" // 新規予約ページへのパス（適宜調整）
        size="large"
        sx={{
          border: 1,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          borderRadius: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          marginBottom: '8px',
          backgroundColor: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.primary.dark,
          }
        }}
      >
        <AddCircleOutlineIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
      </Button>
      <Typography variant="subtitle1" color="textPrimary">新規予約</Typography>
    </Box>

    <Box display="flex" flexDirection="column" alignItems="center">
      <Button 
        component={Link} 
        to="/reservation/confirm" // 予約確認ページへのパス（適宜調整）
        size="large"
        sx={{
          border: 1,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          borderRadius: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          marginBottom: '8px',
          backgroundColor: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.primary.dark,
          }
        }}
      >
        <EventAvailableIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
      </Button>
      <Typography variant="subtitle1" color="textPrimary">予約確認</Typography>
    </Box>
  </Box>
);

export default Menu;
