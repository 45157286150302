import React from 'react';
import { Button } from '@mui/material';
import { Typography, Box, Container } from '@mui/material';

interface LoginPageProps {
  handleSignUp: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ handleSignUp }) => (
  <Container maxWidth="lg">
  <Box sx={{ textAlign: 'left', pt: 2 }}>
    <Typography
      component="h2"
      gutterBottom
      sx={{
        fontSize: {
          xs: '1rem', // モバイルサイズ
          sm: '1rem', // タブレットサイズ以上
        },
        marginBottom: '1rem'
      }}
    >
      予約代行システム
    </Typography>
    <Typography
      variant="h3"
      component="h2"
      gutterBottom
      sx={{
        fontSize: {
          xs: '2rem', // モバイルサイズ
          md: '3rem', // デスクトップサイズ以上
        },
        fontWeight: 'bold',
      }}
    >
      朝起きて、予約、<br></br> すべてお任せ。
    </Typography>
  </Box>
  <Button
        variant="contained"
        onClick={handleSignUp}
        sx={{
          marginTop: '20px',
          fontSize: '100%',
          borderRadius: '96px',
          textTransform: 'none',
          padding: '16px 32px 16px 40px', 
          backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // グラデーションを適用
          color: 'white', // ボタンのテキスト色を白に設定
          '&:hover': { // ホバー状態のスタイルをカスタマイズ
            backgroundImage: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
          },
        }}
      >
        無料ではじめる
      </Button>
</Container>
);

export default LoginPage;
