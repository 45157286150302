import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Typography,
  Container,
  Box,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs"; // Dayjsをインポート
import { createReservationRequest } from "./ScheduleApi";

import { useAuth0 } from "@auth0/auth0-react";

import ReservationSuccessModal from "./ReservationSuccessModal";
import { ReservationFailureModal } from "./ReservationFailureModal";
import { useClient } from './ClientContext';


// バリデーションスキーマ
const validationSchema = Yup.object({
  patientCardNumber: Yup.string()
    .required("診察券番号は必須です"),
  birth: Yup.string()
  .required("生年月日は必須です。")
  .matches(/^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/, "形式が無効です。"),
  reservationDate: Yup.string().required("予約日は必須です"),
  partOfDay: Yup.string().required("時間帯は必須です"),
});

const SekineJibikaExist = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [paypayUrl, setPaypayUrl] = useState<string | undefined>(undefined);
  const today = dayjs();
  const oneMonthLater = today.add(1, "month");
  const { client } =  useClient();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        {" "}
        {/* Containerを追加し、上マージンを適用 */}
        <Formik
          initialValues={{
            patientCardNumber: "",
            birth: "",
            reservationDate: null as Dayjs | null,
            partOfDay: "0",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const formattedDate = values.reservationDate
              ? values.reservationDate.format("YYYY-MM-DD")
              : "";

            const token = await getAccessTokenSilently();
            const appointmentData = {
              patientCardNumber: values.patientCardNumber,
              patientBirth: values.birth,
              reservationDate: formattedDate,
              hospitalName: "sekine-jibika",
              partOfDay: values.partOfDay,
            };
            const result = await createReservationRequest(client, appointmentData);
            if (result.success) {
              setPaypayUrl(result.paypayUrl || undefined);
              setModalOpen(true);
            } else {
              setFailureModalOpen(true);
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <Form>
              <Typography variant="h6">せきね耳鼻咽喉科 事前予約</Typography>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="patientCardNumber"
                  label="診察券番号"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.patientCardNumber && Boolean(errors.patientCardNumber)}
                  helperText={touched.patientCardNumber && errors.patientCardNumber}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
              <TextField
                name="birth"
                label="誕生月日 (9月6日であれば、「0906」と入力)"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.birth && Boolean(errors.birth)}
                helperText={touched.birth && errors.birth}
              />
            </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="予約日 (祝日や臨時休業日は選択しないでください。)"
                  minDate={today}
                  maxDate={oneMonthLater} // 一ヶ月先まで選択可能な最大の日付
                  value={values.reservationDate}
                  onChange={(newValue) => {
                    setFieldValue("reservationDate", newValue);
                  }}
                  format="YYYY-MM-DD"
                  closeOnSelect
                  shouldDisableDate={(date) => {
                    // 日曜日 (0) と水曜日 (3) を禁止
                    return date.day() === 0 || date.day() === 3;
                  }}
                  //   views={["day", "month", "year"]}
                />
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">時間帯</FormLabel>
                <RadioGroup
                  name="partOfDay"
                  value={values.partOfDay} // Ensure this matches the Formik state
                  onChange={handleChange}
                  onBlur={handleBlur}
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="午前(9:30～)"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="午後(15:00～)"
                  />
                </RadioGroup>
              </FormControl>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{ fontSize: "1rem", padding: "10px 24px" }}
                >
                  予約
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <ReservationSuccessModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          paypayUrl={paypayUrl}
        />
        <ReservationFailureModal
          open={failureModalOpen}
          onClose={() => setFailureModalOpen(false)}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default SekineJibikaExist;
