// src/theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#252F3E', // ネイビー色
      contrastText: '#ffffff', // 白色テキストで読みやすく
    },
    secondary: {
      main: '#FF9900', // AWSのオレンジをアクセントカラーとして使用
      contrastText: '#ffffff',
    },
    background: {
      default: '#f2f2f2', // 軽い背景色でコンテンツを際立たせる
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#ffffff', // AppBarの背景色を白に設定
          color: '#232f3e', // テキストとアイコンの色をネイビーに設定
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: '#232f3e', // ボタンのテキストとアイコンの色をネイビーに設定
          borderColor: '#232f3e', // ボタンのボーダー色をネイビーに設定
          '&:hover': {
            backgroundColor: 'rgba(0, 51, 102, 0.04)', // ホバー時の背景色を薄いネイビーに設定
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;


// #ff9900	(255,153,0)
// #146eb4	(20,110,180)
// #000000	(0,0,0)
// #232f3e	(35,47,62)
// #f2f2f2	(242,242,242)