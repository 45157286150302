import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import theme from './theme';

const AppList: React.FC = () => (
  <Box 
  display="flex" 
  justifyContent="center" 
  alignItems="center" 
  flexDirection="column" 
  m={6}
>
  <Button 
    component={Link} 
    to="/reservation/menu" 
    size="large"
    sx={{
      border: 1,
      borderColor: theme.palette.primary.main, // ボタンのボーダー色をネイビーに設定
      color: theme.palette.primary.main, // ボタン内のアイコンやテキストの色をネイビーに設定
      borderRadius: 1,
      p: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      marginBottom: '8px', // アイコンとテキストの間のマージン
      backgroundColor: theme.palette.primary.contrastText, // ボタンの背景色を白に設定
      '&:hover': {
        backgroundColor: theme.palette.action.hover, // ホバー時の背景色を設定
        borderColor: theme.palette.primary.dark, // ホバー時のボーダー色を暗くする
        // 注意: theme.palette.primary.dark が定義されていない場合は別の色を指定
      }
    }}
  >
    <EventIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
  </Button>
  <Typography variant="subtitle1" color="textPrimary">予約代行システム</Typography>
</Box>
  
);

export default AppList;
