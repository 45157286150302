// import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  ThemeProvider,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import theme from "./theme";
import LoginPage from "./LoginPage";
import NewReservation from "./NewReservation";
import ReservationConfirm from "./ReservationConfirm";
import AppList from "./Menu";
import Menu from "./AppList";
import SekineJibika from "./SekineJibika";
import SekineJibikaNew from "./SekineJibikaNew";
import SekineJibikaExist from "./SekineJibikaExist";
import HospitalBForm from "./WakoJibika";
import LoginIcon from "@mui/icons-material/Login";
import Footer from "./Footer";
import { useClient } from "./ClientContext";
import { useEffect } from "react";
import logo from "./assets/images/wakonavi.png";
import { useAuth0 } from '@auth0/auth0-react';
import LegalInformation from "./LegalInformation";
import ServiceDescription from "./ServiceDescription";
import PrivacyPolicy from './PrivacyPolicy';

const App: React.FC = () => {
  const {
    isAuthenticated,
    // user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();
  // console.log("isAuthenticated, isLoading", isAuthenticated, isLoading , window.location.href);
  const { initializeClient } = useClient();


  useEffect(() => {
    const updateClient = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        initializeClient(token);
      }
    };
    updateClient();
  }, [isAuthenticated]);

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout({
      // async openUrl(url:string) {
      //   // localhost用
      //   window.location.replace(window.location.origin);
      // }
    });
  };

  const handleSignUp = () => {
    // Password認証を実装していないとエラーになる
    // loginWithRedirect({authorizationParams: {
    //   screen_hint: "signup",
    // }});
    loginWithRedirect();
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between", paddingTop: "18px" }}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ width: "10rem", cursor: "pointer" }} />
            </Link>
            <div>
              <Button
                color="inherit"
                component={Link}
                to="/service-description"
                style={{ marginRight: '1rem' }}
              >
                概要
              </Button>
              {isAuthenticated ? (
                <Button
                  color="inherit"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  color="inherit"
                  style={{ fontSize: "1.2rem" }}
                  startIcon={<LoginIcon />}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: "2vh" }}></div>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/app-list" />
              ) : (
                <LoginPage handleSignUp={handleSignUp} />
              )
            }
          />
          <Route
            path="/app-list"
            element={isAuthenticated ? <AppList /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/menu"
            element={isAuthenticated ? <Menu /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/new"
            element={isAuthenticated ? <NewReservation /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/confirm"
            element={
              isAuthenticated ? <ReservationConfirm /> : <Navigate to="/" />
            }
          />
          <Route
            path="/reservation/sekine-jibika"
            element={isAuthenticated ? <SekineJibika /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/sekine-jibika/new"
            element={isAuthenticated ? <SekineJibikaNew /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/sekine-jibika/exist"
            element={isAuthenticated ? <SekineJibikaExist /> : <Navigate to="/" />}
          />
          <Route
            path="/reservation/wako-jibika"
            element={isAuthenticated ? <HospitalBForm /> : <Navigate to="/" />}
          />
          <Route
            path="/legal-information"
            element={<LegalInformation />}
          />
          <Route
            path="/service-description"
            element={<ServiceDescription />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
