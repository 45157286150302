import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Typography,
  Container,
  Box,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs"; // Dayjsをインポート
import { createReservationRequest } from "./ScheduleApi";

import { useAuth0 } from "@auth0/auth0-react";

import ReservationSuccessModal from "./ReservationSuccessModal";
import { ReservationFailureModal } from "./ReservationFailureModal";

import { useClient } from './ClientContext';


// バリデーションスキーマ
const validationSchema = Yup.object({
  name: Yup.string()
    .required("氏名は必須です")
    .matches(/^[ァ-ヶー]+$/, "カタカナで入力してください"),
  birth: Yup.string().required("生年月日は必須です。"),
  sex: Yup.string().required("性別は必須です"),
  tel: Yup.string()
    .required("電話番号は必須です")
    .matches(/^\d{10,11}$/, "電話番号をハイフンなしで入力してください"),
  email: Yup.string()
    .email("有効なメールアドレスを入力してください")
    .required("メールアドレスは必須です"),
  reservationDate: Yup.string().required("予約日は必須です"),
  partOfDay: Yup.string().required("時間帯は必須です"),
});

const SekineJibikaNew = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [paypayUrl, setPaypayUrl] = useState<string | undefined>(undefined);
  const today = dayjs();
  const oneMonthLater = today.add(1, "month");
  const { client } =  useClient();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        {" "}
        {/* Containerを追加し、上マージンを適用 */}
        <Formik
          initialValues={{
            name: "",
            birth: "",
            sex: "",
            tel: "",
            email: "",
            reservationDate: null as Dayjs | null,
            partOfDay: "0",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const formattedDate = values.reservationDate
              ? values.reservationDate.format("YYYY-MM-DD")
              : "";
            const formattedBirth = values.birth
              ? dayjs(values.birth).format("YYYYMMDD")
              : "";

            const token = await getAccessTokenSilently();
            const appointmentData = {
              name: values.name,
              birth: formattedBirth,
              sex: values.sex,
              tel: values.tel,
              email: values.email,
              reservationDate: formattedDate,
              hospitalName: "sekine-jibika",
              partOfDay: values.partOfDay,
            };
            const result = await createReservationRequest(client, appointmentData);
            if (result.success) {
              setPaypayUrl(result.paypayUrl || undefined);
              setModalOpen(true);
            } else {
              setFailureModalOpen(true);
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <Form>
              <Typography variant="h6">せきね耳鼻咽喉科 事前予約</Typography>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="name"
                  label="氏名(カタカナ)"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  label="生年月日"
                  type="date" // type を date に設定することで、ブラウザのデフォルトの日付ピッカーを使用します。
                  onChange={(event) => {
                    setFieldValue("birth", event.target.value);
                  }}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true, // これにより、ラベルがテキストフィールドの上部に固定されます。
                  }}
                  inputProps={{
                    pattern: "\\d{4}/\\d{2}/\\d{2}", // YYYY/MM/DD 形式の入力を期待するパターン
                  }}
                />
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">性別</FormLabel>
                <RadioGroup name="sex" onChange={handleChange} row>
                  <FormControlLabel value="1" control={<Radio />} label="男" />
                  <FormControlLabel value="2" control={<Radio />} label="女" />
                </RadioGroup>
                <Typography color="error">
                  {touched.sex && errors.sex}
                </Typography>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="tel"
                  label="電話番号"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tel && Boolean(errors.tel)}
                  helperText={touched.tel && errors.tel}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="email"
                  label="メールアドレス"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="予約日 (祝日や臨時休業日は選択しないでください。)"
                  minDate={today}
                  maxDate={oneMonthLater} // 一ヶ月先まで選択可能な最大の日付
                  value={values.reservationDate}
                  onChange={(newValue) => {
                    setFieldValue("reservationDate", newValue);
                  }}
                  format="YYYY-MM-DD"
                  closeOnSelect
                  shouldDisableDate={(date) => {
                    // 日曜日 (0) と水曜日 (3) を禁止
                    return date.day() === 0 || date.day() === 3;
                  }}
                  //   views={["day", "month", "year"]}
                />
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">時間帯</FormLabel>
                <RadioGroup
                  name="partOfDay"
                  value={values.partOfDay} // Ensure this matches the Formik state
                  onChange={handleChange}
                  onBlur={handleBlur}
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="午前(9:30～)"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="午後(15:00～)"
                  />
                </RadioGroup>
              </FormControl>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{ fontSize: "1rem", padding: "10px 24px" }}
                >
                  予約
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <ReservationSuccessModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          paypayUrl={paypayUrl}
        />
        <ReservationFailureModal
          open={failureModalOpen}
          onClose={() => setFailureModalOpen(false)}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default SekineJibikaNew;
