import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ marginTop: '10vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
      <Container maxWidth="lg">
        <Typography variant="subtitle1" align="center" color="textSecondary" sx={{ fontSize: '11px' }}>
          © {new Date().getFullYear()} Tomitty All Rights Reserved.
          {' | '}
          <Link component={RouterLink} to="/legal-information" color="inherit">
            特定商取引法に基づく表記
          </Link>
          {' | '}
          <Link component={RouterLink} to="/privacy-policy" color="inherit">
            プライバシーポリシー
          </Link>
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
