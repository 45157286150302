import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SekineJibika: React.FC = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" m={6}>
    <Button component={Link} to="/reservation/sekine-jibika/exist" variant="outlined" color="primary" style={{ fontSize: '1.2rem' }} sx={{ margin: 1 }}>
      診察券番号ありの方
    </Button>
    <Button component={Link} to="/reservation/sekine-jibika/new" variant="outlined" color="primary" style={{ fontSize: '1.2rem', marginBottom: '2rem'}} sx={{ margin: 1 }}>
      初めて受診の方
    </Button>
  </Box>
);

export default SekineJibika;
