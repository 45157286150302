// SuccessModal.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ReservationSuccessModalProps {
  open: boolean;
  onClose: () => void;
  paypayUrl?: string;
}

const ReservationSuccessModal: React.FC<ReservationSuccessModalProps> = ({
  open,
  onClose,
  paypayUrl
}) => {
  const navigate = useNavigate();

  const handlePayment = () => {
    if (paypayUrl) {
      window.open(paypayUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleViewReservations = () => {
    navigate('/reservation/confirm');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>予約が完了しました</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          予約が確定しました。
        </Typography>
        {paypayUrl && (
          <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
            オンラインでの支払いが可能です。「PayPayで支払う」ボタンをクリックして支払いを完了してください。
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {paypayUrl && (
          <Button onClick={handlePayment} color="primary" variant="contained">
            PayPayで支払う
          </Button>
        )}
        <Button onClick={handleViewReservations} color="primary">
          予約一覧を見る
        </Button>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationSuccessModal;
