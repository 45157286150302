
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          個人情報保護方針（プライバシーポリシー）
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          1. 個人情報の収集と利用目的
        </Typography>
        <Typography paragraph>
          当サービスは、予約代行サービスの提供のために必要な個人情報を収集します。収集する情報には、氏名、生年月日、性別、電話番号、メールアドレスなどが含まれます。これらの情報は、予約の管理、サービスの改善、お客様とのコミュニケーションのために利用されます。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          2. 個人情報の管理
        </Typography>
        <Typography paragraph>
          収集した個人情報は、適切な安全管理措置を講じて保護し、不正アクセス、紛失、破壊、改ざん、漏洩などを防止するよう努めます。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          3. 個人情報の第三者提供
        </Typography>
        <Typography paragraph>
          法令に基づく場合や、人の生命、身体または財産の保護のために必要がある場合を除き、お客様の同意なく第三者に個人情報を提供することはありません。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          4. 個人情報の開示・訂正・削除
        </Typography>
        <Typography paragraph>
          お客様ご本人は、予約確認画面から個人情報（予約情報）を確認し、削除することができます。予約のキャンセルを行うことで、関連する個人情報が削除されます。ただし、法令に基づき保存が必要な場合は、この限りではありません。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          5. クッキーの使用
        </Typography>
        <Typography paragraph>
          当サービスでは、ユーザー体験の向上やサービスの改善のためにクッキーを使用することがあります。クッキーの使用を望まない場合は、ブラウザの設定で無効にすることができます。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          6. プライバシーポリシーの変更
        </Typography>
        <Typography paragraph>
          本ポリシーの内容は、法令の変更や当サービスの提供内容の変更に伴い、予告なく変更される場合があります。予約の都度更新がないかご確認ください。
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          7. お問い合わせ
        </Typography>
        <Typography paragraph>
          本ポリシーに関するお問い合わせは、以下の連絡先までお願いいたします。
        </Typography>
        <Typography>
          メールアドレス: tomitty3@gmail.com
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;