// ReservationFailureModal.tsx
import React from 'react';
import { Modal, Box, Typography, Button, useTheme } from '@mui/material';

interface ReservationFailureModalProps {
  open: boolean;
  onClose: () => void;
}

export const ReservationFailureModal: React.FC<ReservationFailureModalProps> = ({ open, onClose }) => {
    const theme = useTheme();
    return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="reservation-failure-modal-title"
      aria-describedby="reservation-failure-modal-description"
    >
     <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        [theme.breakpoints.down('sm')]: {
          width: '80%',
          maxHeight: '90%',
        },
      }}>
        <Typography id="reservation-failure-modal-title" variant="h6" component="h2">
          予約受付失敗
        </Typography>
        <Typography id="reservation-failure-modal-description" sx={{ mt: 2 }}>
          予約受付処理中に問題が発生しました。入力内容を確認し、もう一度お試しください。
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button onClick={onClose} variant="contained">OK</Button>
        </Box>
      </Box>
    </Modal>
  );
};
