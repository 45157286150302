import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { ClientProvider } from './ClientContext';

// import { Amplify } from 'aws-amplify';

// Amplify.configure({
//   API: {
//     GraphQL: {
//       endpoint: 'https://navypoe54ndunnhkz3dj3ois3u.appsync-api.ap-northeast-1.amazonaws.com/graphql',
//       region: 'ap-northeast-1',
//       defaultAuthMode: 'oidc',
//     }
//   }
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN ?? "default string"}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? "default string"}
    // onRedirectCallback={onRedirectCallback}
    authorizationParams={{
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
      scope: "openid profile email"
    }}
    // useRefreshTokens
    cacheLocation="localstorage"
  >
    <ClientProvider>
    <App />
    </ClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
