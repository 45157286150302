import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField,TextFieldProps, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Typography, Container } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider,  } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from "dayjs"

import { scheduleAppointment } from './ScheduleApi';

import { useAuth0 } from '@auth0/auth0-react';


// バリデーションスキーマ
const validationSchema = Yup.object({
  name: Yup.string().required('氏名は必須です').matches(/^[ァ-ヶー]+$/, 'カタカナで入力してください'),
  birth: Yup.string().required('生年月日は必須です。').matches(/^\d{8}$/, '形式が無効です。'),
  sex: Yup.string().required('性別は必須です'),
  tel: Yup.string().required('電話番号は必須です').matches(/^\d{10,11}$/, '電話番号をハイフンなしで入力してください'),
  email: Yup.string().email('有効なメールアドレスを入力してください').required('メールアドレスは必須です'),
  reservationDate: Yup.string().required('予約日は必須です'),
  partOfDay: Yup.string().required("時間帯は必須です"),
});

const WakoJibika = () => {
    const {  getAccessTokenSilently } =
    useAuth0();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Container maxWidth="sm" sx={{ mt: 4 }}> {/* Containerを追加し、上マージンを適用 */}
    <Formik
      initialValues={{ name: '', birth: '', sex: '', tel: '', email: '', reservationDate: null as Dayjs | null, partOfDay: ''  }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const formattedDate = values.reservationDate ? values.reservationDate.format('YYYY-MM-DD') : '';
        const token = await getAccessTokenSilently();
        const appointmentData = {
            name: values.name,
            birth: values.birth,
            sex: values.sex,
            tel: values.tel,
            email: values.email,
            reservationDate: formattedDate,
            hospitalName: 'sekine-jibika',
            partOfDay: values.partOfDay
          }; 
        scheduleAppointment(token,appointmentData);

        setSubmitting(false);
      }}
    >
      {({ errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Typography variant="h6">和光耳鼻咽喉科 事前予約</Typography>
          <Typography variant="h6">Comming soon.</Typography>
        </Form>
      )}
    </Formik>
    </Container>
    </LocalizationProvider>
  );
};

export default WakoJibika;
