import React, { useEffect, useState } from 'react';
import { getReservations } from './graphql/queries'; // AppSync のクエリ
import { deleteReservation } from './graphql/mutations'; // AppSync のクエリ
import { type Reservation, type DeleteReservationMutationVariables } from './API';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import { useClient } from './ClientContext';

// 病院名の変換マップを定義
const hospitalNameMap = new Map([
  ['sekine-jibika', 'せきね耳鼻咽喉科'],
  // 他に変換する名前がある場合は、ここに追加します
]);

const ReservationConfirm = () => {
  const [events, setEvents] = useState<Array<Reservation | null> | null>(null);
  const { client } =  useClient();

  useEffect(() => {
    if (client) {
      fetchEvents(); // 認証されている場合にのみイベントをフェッチ
    }
  }, [client]);

  const fetchEvents = async () => {
    console.log('fetching events');

    try {
      const eventData = await client?.graphql({
        query: getReservations,
      });
      if (eventData.data.getReservations) {
        // eventData.data.getAgentEvents が配列であることを確認してから状態を更新
        setEvents(eventData.data.getReservations);
      }
    } catch (err) {
      console.error('error fetching events', err);
    }
  };

  const handleDelete = async (reservationHash: string) => {
    try {
      if (!client){
        console.log("You are not logined.");
        return;
      }
      console.log("Reservation hash:", reservationHash);
      const variables: DeleteReservationMutationVariables = {
        ReservationHash: reservationHash,
      };
      const response = await client?.graphql({
        query: deleteReservation,
        variables: variables
      });
      console.log(response);
      await fetchEvents(); // 再取得
    } catch (err) {
      console.error('error deleting event', err);
    }
  };

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Hospital Name</TableCell>
            <TableCell>Name/Number</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      {events && events.map((event, index) => {
        // event.AppointmentDataをパースしてHospitalNameとNameを取得
        console.log('Received raw event data:', JSON.stringify(event, null, 2));
        const appointmentData = event?.AppointmentData ? JSON.parse(event.AppointmentData).appointmentData : {};
        const hospitalName = appointmentData.hospitalName;
        const name = appointmentData.name || appointmentData.patientCardNumber; 

        return (
          <React.Fragment key={event?.ReservationHash || index}>
            {event && (
              <TableRow>
                <TableCell>{event.ReservationDate}</TableCell>
                <TableCell>{hospitalNameMap.get(hospitalName) || hospitalName}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(event.ReservationHash)}>
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
      </Table>
    </Container>
  );
};

export default ReservationConfirm;
