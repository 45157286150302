import React, { createContext, useContext, ReactNode, useState, FunctionComponent } from 'react';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';

// import { AuthTokens, JWT, JWTCreator } from '@aws-amplify/core/src/singleton/Auth/types'

// From https://github.com/awslabs/aws-jwt-verify/blob/main/src/safe-json-parse.ts
// From https://github.com/awslabs/aws-jwt-verify/blob/main/src/jwt-model.ts
interface JwtPayloadStandardFields {
	exp?: number; // expires: https://tools.ietf.org/html/rfc7519#section-4.1.4
	iss?: string; // issuer: https://tools.ietf.org/html/rfc7519#section-4.1.1
	aud?: string | string[]; // audience: https://tools.ietf.org/html/rfc7519#section-4.1.3
	nbf?: number; // not before: https://tools.ietf.org/html/rfc7519#section-4.1.5
	iat?: number; // issued at: https://tools.ietf.org/html/rfc7519#section-4.1.6
	scope?: string; // scopes: https://tools.ietf.org/html/rfc6749#section-3.3
	jti?: string; // JWT ID: https://datatracker.ietf.org/doc/html/rfc7519#section-4.1.7
	sub?: string; // JWT sub https://datatracker.ietf.org/doc/html/rfc7519#section-4.1.2
}

type JsonPrimitive = null | string | number | boolean;

/** JSON array type */
type JsonArray = JsonPrimitive[];

/** JSON Object type */
interface JsonObject {
	[x: string]: JsonPrimitive | JsonArray | JsonObject;
}

export type JwtPayload = JwtPayloadStandardFields & JsonObject;

export interface JWT {
	payload: JwtPayload;
	toString(): string;
}

export interface AuthTokens {
	idToken?: JWT;
	accessToken: JWT;
	/**
	 * @deprecated
	 * Use getCurrentUser to access signInDetails
	 */
	// signInDetails?: AWSAuthSignInDetails;
}

export type JWTCreator = (stringJWT: string) => JWT;


const jwtCreator: JWTCreator = (stringJWT: string): JWT => {
  // ここでJWTのペイロードを解析する処理を実装
  const payload = {/* トークンからペイロードを解析 */};
  
  return {
    payload,
    toString: () => stringJWT,
  };
};

class CustomTokenProvider {
  private accessToken: string;

  constructor(token: string) {
      this.accessToken = token;
  }

  async getTokens({ forceRefresh = false } = {}): Promise<AuthTokens | null> {
      // この実装ではforceRefreshオプションは無視されますが、
      // 必要に応じてトークンの更新ロジックをここに追加できます。
      return {
          accessToken: jwtCreator(this.accessToken)
      };
  }
}

// クライアント型を定義（具体的な型は実装による）
interface Client {
  // クライアントの型定義
  graphql: (request: any) => any;
}

// コンテキストの値の型を定義
interface ClientContextType {
  client: Client | null;
  initializeClient: (token: string) => void;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientProvider: FunctionComponent<ClientProviderProps> = ({ children }) => {
  const [client, setClient] = useState<Client | null>(null);

  const initializeClient = (token:string) => {
    // ここでクライアントを生成するロジックを実装
    const tokenProvider = new CustomTokenProvider(token);
    Amplify.configure({
      API: {
        GraphQL: {
          endpoint: 'https://navypoe54ndunnhkz3dj3ois3u.appsync-api.ap-northeast-1.amazonaws.com/graphql',
          region: 'ap-northeast-1',
          defaultAuthMode: 'oidc',
        }
      },
    },{
      Auth:{
        tokenProvider: tokenProvider
      }
    });
    const newClient = generateClient();
    setClient(newClient);
  };

  return (
    <ClientContext.Provider value={{ client, initializeClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): ClientContextType => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};
