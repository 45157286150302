// api.ts
import { type CreateReservationMutationVariables } from './API';
import { createReservation } from './graphql/mutations';

type AppointmentDataForNew = {
  name: string;
  birth: string;
  sex: string;
  tel: string;
  email: string;
  reservationDate: string;
  hospitalName: string;
  partOfDay: string;
};


type AppointmentDataForExist = {
  patientCardNumber: string;
  patientBirth: string;
  reservationDate: string;
  hospitalName: string;
  partOfDay: string;
};

interface ReservationResult {
  success: boolean;
  paypayUrl: string | null;
}

export const createReservationRequest = async (client: any, appointmentData: AppointmentDataForNew | AppointmentDataForExist): Promise<ReservationResult> => {
  try {
    if (!client) {
      console.log("You are not logged in.");
      return { success: false, paypayUrl: null };
    }
    console.log(JSON.stringify(appointmentData));
    const variables: CreateReservationMutationVariables = {
      appointmentData: JSON.stringify(appointmentData),
    };
    const response = await client.graphql({
      query: createReservation,
      variables: variables,
    });
    console.log(response);
    if (response.data && response.data.createReservation) {
      const success = response.data.createReservation.success;
      const paypayUrl = response.data.createReservation.paypayUrl || null;
      console.log("paypayUrl: ", paypayUrl);
      return { success, paypayUrl };
    }
    console.error("予約の作成に失敗しました。");
    return { success: false, paypayUrl: null };
  } catch (err) {
    console.error('error creating reservation', err);
    return { success: false, paypayUrl: null };
  }
};


export const scheduleAppointment = async (
    token: string,
    appointmentData: AppointmentDataForNew | AppointmentDataForExist
  ): Promise<boolean> => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/schedule-appointment`;

      console.log(JSON.stringify(appointmentData));
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appointmentData })
      });
  
      if (!response.ok) {
        throw new Error('Something went wrong with the API request.');
      }
      // ステータスコードが 200 の場合の処理
      if (response.status === 200) {
        // 予約完了のアラートを表示
        return true;
      } else {
        console.log(response.status );
        return false;
      }
      // ここで応答データを処理（例：成功メッセージの表示）
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
